.container
  container()

.link
  link($dark_blue)

.hidden
  display none !important

.option
  padding-left 10px

  &.option__dedicated
    font-weight 700
    padding-left 0

.input__editor
  border: 1px solid #a2c6e9;
  border-radius: 10px;
  padding 0 0 20px
  background-color #FFFFFF

.input__button
  display inline-flex
  margin-top 10px

.input__error
  color $red
  font-size 12px
  display flex
  width 100%
  font-weight bold

.input__message
  color $black
  font-size 12px
  line-height 24px
  display flex
  width 100%

.input__info
  color $dark_blue
  font-size 12px
  line-break: anywhere;

  a
    text-decoration underline

/*Сетка*/

.title_disabled  // заморозка текста
  color $gray

.document_url
  text-decoration: underline;

.row
  grid()

.row--width-fix
  grid()
  width calc(100% + 16px)

.col-1
  grid_item(100%)

.col-2
  grid_item(50%)

  +below(768px)
    grid_item(100%)

.col-3
  grid_item(33.333333%)

  +below(768px)
    grid_item(100%)

.col-4
  grid_item(25%)

  +below(960px)
    grid_item(50%)

  +below(768px)
    grid_item(100%)

.col-5
  grid_item(20%)

  +below(960px)
    grid_item(25%)

  +below(768px)
    grid_item(33.333333%)

  +below(480px)
    grid_item(100%)

._col-1
  grid_item(8.33333333%)

  .form__item
    margin 0

._col-2
  grid_item(16.6666667%)

  .form__item
    margin 0

._col-3
  grid_item(25%)

  .form__item
    margin 0

._col-4
  grid_item(33.3333333%)

  .form__item
    margin 0

._col-5
  grid_item(41.6666667)

  .form__item
    margin 0

._col-6
  grid_item(50%)

  .form__item
    margin 0

._col-7
  grid_item(58.3333333%)

  .form__item
    margin 0

._col-8
  grid_item(66.6666666%)

  .form__item
    margin 0

._col-9
  grid_item(75%)

  .form__item
    margin 0

._col-10
  grid_item(83.3333333%)

  .form__item
    margin 0

._col-11
  grid_item(91.6666666%)

  //.form__item
  //  margin 0

._col-12
  grid_item(100%)

  .form__item
    margin 0
.flex
  display flex


::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb{
  background: $dark_blue;
  border-radius: 30px;
  transition .3s
}
::-webkit-scrollbar-thumb:hover{
  background: $blue;
  cursor pointer
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track{
  background: #F0F0F0;
}

.col24-1 {
  grid_item(4.16666667%)

  .form__item {
    margin 0
  }
}


.col24-2 {
  grid_item(8.33333334%)

  .form__item {
    margin 0
  }
}


.col24-3 {
  grid_item(12.5%)

  .form__item {
    margin 0
  }
}


.col24-4 {
  grid_item(16.6666667%)

  .form__item {
    margin 0
  }
}


.col24-5 {
  grid_item(20.8333333%)

  .form__item {
    margin 0
  }
}


.col24-6 {
  grid_item(25%)

  .form__item {
    margin 0
  }
}


.col24-7 {
  grid_item(29.1666667%)

  .form__item {
    margin 0
  }
}


.col24-8 {
  grid_item(33.3333334%)

  .form__item {
    margin 0
  }
}


.col24-9 {
  grid_item(37.5%)

  .form__item {
    margin 0
  }
}


.col24-10 {
  grid_item(41.6666667%)

  .form__item {
    margin 0
  }
}


.col24-11 {
  grid_item(45.8333334%)

  .form__item {
    margin 0
  }
}


.col24-12 {
  grid_item(50%)

  .form__item {
    margin 0
  }
}


.col24-13 {
  grid_item(54.1666667%)

  .form__item {
    margin 0
  }
}


.col24-14 {
  grid_item(58.3333338%)

  .form__item {
    margin 0
  }
}


.col24-15 {
  grid_item(62.5000000%)

  .form__item {
    margin 0
  }
}


.col24-16 {
  grid_item(66.6666667%)

  .form__item {
    margin 0
  }
}


.col24-17 {
  grid_item(70.8333333%)

  .form__item {
    margin 0
  }
}


.col24-18 {
  grid_item(75%)

  .form__item {
    margin 0
  }
}


.col24-19 {
  grid_item(79.1666667%)

  .form__item {
    margin 0
  }
}


.col24-20 {
  grid_item(83.3333333%)

  .form__item {
    margin 0
  }
}


.col24-21 {
  grid_item(87.5000000%)

  .form__item {
    margin 0
  }
}


.col24-22 {
  grid_item(91.6666667%)

  .form__item {
    margin 0
  }
}


.col24-23 {
  grid_item(95.83333341%)

  .form__item {
    margin 0
  }
}


.col24-24 {
  grid_item(100%)

  .form__item {
    margin 0
  }
}
