.pagination
  padding 0
  display flex
  justify-content center
  align-items center
  list-style none
  margin 24px auto 32px

  &__icon
    display flex
    align-items center
    justify-content center
    width 12px
    height 12px

    path
      fill $dark_blue

    &--rotated
      transform rotate(180deg)

  &__link
    display flex
    align-items center
    justify-content center
    font-weight normal
    border none
    font-size 16px
    line-height 18px
    color $dark_blue
    border-radius: 10px;
    width 50px
    height 50px
    background none
    cursor pointer

    &--disabled
      cursor: initial;

    &:not(&--disabled):not(&--active):hover
    &:not(&--disabled):not(&--active):focus
      background $gray
      color $dark

    &--active
      cursor initial
      background $dark_blue
      color $white
      box-shadow 0 10px 15px rgba($dark_blue, 0.2)
