.no-overflow {
  overflow hidden
}

.swal2-title {
  font-weight bold !important
  font-size 18px !important
  line-height 21px !important
  color $black !important
}

.vue-notification {
  margin 0 15px 15px 0 !important

  &.error {
    background: $red !important;
    border-left-color: darken($red, 50%) !important;
  }

  &.warn {
    background: $orange !important;
    border-left-color: darken($orange, 50%) !important;
  }

  &.success {
    background: $green !important;
    border-left-color: darken($green, 50%) !important;
  }
}

.modal {
  fixed left top
  width 100%
  height 100%
  z-index 999
  overflow hidden
  background: alpha($dark, 50%);

  &__content {
    width 100%
    height 100%
    position relative
    display none
    align-items flex-start
    justify-content center
    overflow-y auto

    &:last-child {
      display flex
    }
  }

  &__background {
    fixed left top
    width 100%
    height 100%
    z-index 0
    cursor pointer
  }

  &__body {
    display flex
    width 100%
    margin: 30px 0;
  }
}

.modal-body {
  background $white
  align-self flex-start
  margin auto
  max-width 400px
  width: 100%;
  border-radius 10px
  box-shadow alpha($dark_blue, 20%) 0 30px 60px
  transition transform 0.3s
  z-index 2

  &__list {
    list-style none

    li {
      a {
        display flex
        border-top 1px solid $darkgray
        padding 10px 15px

        &:hover
        &:focus {
          background $gray
          color $dark_blue
        }
      }
    }
  }

  &__footer {
    border-top 1px solid $darkgray
    padding 10px 15px
  }

  &__header {
    display flex
    align-items center
    justify-content space-between
    gap 15px
    padding 15px

    span:not(.modal-body__close) {
      flex 1
      font-size 1.3em
      font-family font_bold
      color $dark_blue
    }
  }

  &__close {
    width 30px
    height 30px
    display flex
    align-items center
    justify-content center
    border-radius 10px
    cursor pointer
    transition background 0.3s

    svg {
      width 10px
      opacity 0.5
      transition all 0.3s
    }

    &:hover
    &:focus {
      background $gray

      svg {
        stroke $dark_blue
        opacity 1
      }
    }
  }
}
