.breadcrumb
  padding 20px 0

  .container
    display flex
    align-items flex-start
    justify-content space-between
    flex-direction column

    +below(768px)
      display block

.breadcrumb__title
  margin 0 0 5px
  padding 0
  font-size 1.3em
  flex 1

  +below(768px)
    margin 0 0 15px

.breadcrumb__list
  flex 1
  display flex
  flex-wrap wrap
  justify-content flex-end
  margin-bottom -10px

  +below(768px)
    justify-content flex-start

  li
    list-style none

    a
      link($dark_blue)

    &::after
      content '/'
      display inline-flex
      margin 0 10px


    &:last-child::after
        display none
