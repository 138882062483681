svg(color = var(--main_dark)) {
  path[fill],
  rect[fill] {
    transition .3s
    fill color
  }

  path[stroke]
  rect[stroke] {
    transition .3s
    stroke color
  }

  rect[stroke][fill]
  path[stroke][fill] {
    transition .3s
    fill transparent
    stroke color
  }
}
