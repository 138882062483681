@import "~@/stylus/mixins/svg.styl"
.map-ms {
  background: var(--color_white_o85);
  border-radius: var(--radius);
  width 290px
  transition width .3s
  box-shadow: 5px 6px 12px var(--color_black_o08);

  &--open {
    width 400px
  }

  &--open &__list {
    overflow-y auto
    height: 150px;
    margin 0 24px 16px
    scrollbar-width: thin;
    &--for-status {
      height auto
    }
  }

  &__list {
    transition height .3s
    overflow hidden
    position relative
    height 0
    display flex
    flex-direction column
    gap 18px
    margin 0 24px 0

    &--for-status {
      gap 12px
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 100px;
    }
  }

  &__item {
    display grid
    align-items: flex-start;
    grid-template-columns 20px 24px auto minmax(24px, 34px)
    grid-gap 6px
    flex-shrink 0
    padding-right: 16px;
    cursor pointer

    &--for-status {
      grid-template-columns 20px auto 56px
      grid-gap 10px
    }

    &--no-icon {
      grid-template-columns 24px auto
    }

    & > .icon svg {
      width 20px
      svg(var(--main_color))
    }
  }

  &__check {
    background: transparent;
    border 1px solid var(--main_border_color)
    height 20px
    width 20px
    border-radius: 2px;

    .icon {
      display flex
      align-items: center;
      justify-content: center;
      height: 100%;

      svg {
        width: 10px;
        height: 10px;
      }
    }
  }

  &__item-title {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color var(--color_dark);
  }

  &__item-count {
    font-weight: 400;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
    display flex
    justify-content flex-end

    &--color {
      border-radius 30px
      width 56px
      height 24px
      display: flex;
      justify-content center
      color var(--color_white)
      background var(--color_gray)

      &-y {
        background-color var(--color_yellow_night)
      }

      &-r {
        background-color var(--color_red)
      }

      &-g {
        background-color var(--color_green)
      }

      &-b {
        background-color var(--color_light_blue)
      }
    }
  }

  &__title {
    font-weight: 400;
    font-size: var(--color_gray_dark);
    line-height: 20px;
    user-select none
    display flex
    align-items center
    justify-content space-between
    padding 16px 0
    margin 0 24px
    cursor pointer
    flex-shrink 0

    .icon {
      display flex

      svg {
        width 20px
        height 20px
        svg(var(--color_gray_dark))
      }
    }
  }
}
