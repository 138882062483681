/*************Clearfix*************/
clearfix()
  &:before
  &:after
    content ""
    display table

  &:after
    clear both

  zoom 1 if support-for-ie

/***************Buttons********************/
button($bg, $color)
  position relative
  background $bg
  border 1px solid $bg
  color $color
  margin 0
  padding 5px 20px
  height 40px
  border-radius 10px
  cursor pointer
  display inline-flex
  align-items center
  justify-content center
  text-align center
  overflow hidden
  transition all 0.3s
  z-index 1

  &:hover
  &:focus
    border-color darken($bg, 10%)
    background darken($bg, 10%)
    color $color

  &:active
    transform scale(0.95)

/***************Link***************/
link(arguments)
  color arguments
  display inline
  position relative
  cursor pointer
  box-shadow inset 0 -1px 0 0 alpha(arguments, 30%)
  transition all 0.3s

  &:hover
  &:focus
    color $dark_blue
    box-shadow inset 0 -1px 0 0 alpha(arguments, 0%)

/*************Hover img effect*************/
imagehover(arguments)
  position relative
  display block

  &:before
    content ''
    absolute right top
    border-radius 0
    width 100%
    height 100%
    color $white
    background arguments
    opacity 0
    transition all 0.3s
    z-index 1

  &:hover
  &:focus
    &::before
      opacity 0.1

/**********LazyLoad*************/
ratio($width, $height)
  position relative
  display block

  &::before
    display block
    content ""
    width 100%
    padding-top ($height / $width) * 100%

  > .image
    absolute left top right bottom
    opacity 0
    transition opacity 0.3s

    &.loaded
      opacity 1

/**************container****************/
container()
  max-width 1400px
  padding-left 50px
  padding-right 50px
  margin 0 auto

  +below(980px)
    padding-left 30px
    padding-right 30px

/************Grid****************/
grid()
  display flex
  flex-flow row wrap
  margin -8px

grid_item(arguments)
  flex-basis "calc(%s - (8px * 2))" % arguments
  margin 8px

svg(color = var(--main_dark)) {
  path[fill],
  rect[fill] {
    transition .3s
    fill color
  }

  path[stroke]
  rect[stroke] {
    transition .3s
    stroke color
  }

  rect[stroke][fill]
  path[stroke][fill] {
    transition .3s
    fill transparent
    stroke color
  }
}
