.aside
  position: -webkit-sticky;
  position: sticky;
  top 20px
  z-index 2
  background $gray
  border 1px solid $darkgray
  border-radius 10px
  padding 24px
  margin-left 30px
  margin-bottom 30px
  width 100%
  max-width 350px

  .input:not([disabled])
    background $white

  +below(768px)
    max-width none
    margin 0

  +below(480px)
    padding 20px

.aside__item
  margin-bottom 10px

  &:last-child
    margin-bottom 0

.langs
  button
    width 100%
    margin-bottom 10px

    &.active
      background $darkgray
      color $dark_blue
      border-color $darkgray
      cursor default

    &:last-child
      margin-bottom 0

.node_actions
  button
    width 100%
