@import '~@/stylus/mixins/textcut.styl'
@import "~@/stylus/mixins/svg.styl"

.map-modal {
  max-width 435px
  width 100%
  background: var(--color_white);
  box-shadow: 5px 6px 12px var(--color_black_o08);
  border-radius: 20px;
  display: grid;
  grid-template-rows: 52px auto;
  align-items start

  &__hint {
    position relative
    margin 0 16px 16px
    width calc(100% - 32px)
    height 290px
    display flex
  }

  &__hint-image {
    width 100%
    height 100%
    absolute left top
    object-fit cover
    object-position center
    border-radius var(--radius)
  }

  &__hint-gradient {
    background: linear-gradient(360deg, rgba(0, 0, 0, 0.5) 18.17%, rgba(0, 0, 0, 0) 41.18%);
    width 100%
    height 100%
    border-radius var(--radius)
    absolute left top
  }

  &__hint-text {
    absolute left 14px bottom 14px right 14px
    font-weight: 500;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_white);
    textcut(3)
  }

  &__header {
    display grid
    grid-template-columns 34px auto
    grid-gap 8px
    align-items center
    padding 8px 16px
    border-radius 20px 20px 0 0
    height 52px
  }

  &__header-icon {
    svg {
      width 32px
      height 32px
    }
  }

  &__title {
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_black);
  }

}
