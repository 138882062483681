/*****************Forms**********************/
input
textarea
select
  background none
  border 1px solid darken($gray, 20%)
  padding 5px 10px
  height 40px
  font-size 1em
  border-radius 10px
  font-family font_regular
  width 100%
  box-sizing border-box
  outline none
  color $dark_blue
  background $white
  transition all 0.3s

  &[disabled]
    background $darkgray

  &:not(.multiselect__input):focus
    box-shadow 0 0 0 3px alpha($dark_blue, 20%)

  &.is-invalid
    box-shadow 0 0 0 3px alpha($red, 20%)

  &[type="checkbox"]
    width auto
    margin 3px 5px 0 0
    float left

  &[readonly="readonly"]
    background $white !important
    border 1px solid darken($gray, 10%)

    &.error
      border-color $red !important

  &.select2-search__field
    margin-top 0 !important

.form__messages

  &.form__messages__error
    color $red

textarea
  width 100%
  height auto
  max-height 150px
  padding 12px 14px
  resize vertical

select
  appearance none
  -moz-appearance none
  -webkit-appearance none
  padding 5px 40px 5px 10px
  display inline-flex
  align-items center
  font-family font_regular
  justify-content center
  background-image linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
  background-position calc(100% - 20px) calc(1em + 3px), calc(100% - 15px) calc(1em + 3px)
  background-size 5px 5px, 5px 5px
  background-repeat no-repeat

.form__row
  display flex
  flex-wrap wrap
  position relative
  align-items flex-end

  .form__item
    margin 0 10px 10px 0

  .form__actions
    margin 0 10px 10px 0

.filters__main
.filters__actions

  .form__actions
    button
      button($gray, $dark_blue)
      margin-right 10px

.nowrap
  flex-wrap nowrap

  .form__actions
    flex-wrap nowrap

.full
  input
  select
  textarea
    width 100%

.form__actions
  display flex
  flex-wrap wrap
  align-items center
  margin-bottom -10px

  button
    margin 0 10px 0 0

.form__item
  margin-bottom 15px

  label
    font-size 0.9em
    font-family font_bold
    display block
    line-height 1.5em
    margin-bottom 5px

  &.checkbox
    display flex
    align-items center
    flex-wrap wrap

    .input__error
      width 100%

    label
      margin 0
      cursor pointer

    input
      height auto
      margin 0 5px 0 0

.form__remember
  display flex

  input
    height auto

  label
    margin 0

.form__add_more
  margin-top 20px

.crp
  fixed top left
  width 100%
  height 100%
  z-index 999
  display flex
  align-items center
  justify-content center

  &__container
    overflow hidden

  &__image
    width 100%
    max-width 100%
    height 270px

  &__buttons
    display flex
    align-items center
    padding 16px

    button
      button($dark_blue, $white)
      height 30px
      padding 6px 6px
      border none
      margin 0 1px
      border-radius 10px !important

      &:not(:last-child)
        border-right 1px solid darken($dark_blue, 50%)

      &:first-child
        border-radius 10px 0 0 10px

      &:last-child
        border-radius 0 10px 10px 0

      svg
        width 24px
        height 24px

.ck-editor__editable
  min-height 150px

.image
  width: 220px;
  height: 220px;
  object-fit: contain;
  object-position: center;
  background $darkgray

  +below(480px)
    height 150px

  &__alt
  &__info

    input
      width auto

  &__name
    display flex
    align-items center
    font-weight normal
    margin-bottom 10px

    &__title
      color $dark_blue

    &__size
      margin-left 5px
      color $dark_blue

  &__container
    display flex
    flex-direction column
    margin-bottom 20px

  &__body
    display grid
    grid-template-columns auto 1fr
    grid-gap 20px

    +below(480px)
      grid-template-columns 1fr

.images
  margin-top 30px
  display grid
  grid-template-columns repeat(5, 1fr)
  grid-gap 5px

  &__item
    position relative

    button
      background none
      border none
      padding 5px
      width 20px
      height 20px
      display flex
      align-items center
      justify-content center
      absolute top right
      transform translate(50%, -50%)
      background $red
      border-radius 50%
      z-index 1
      cursor pointer

      &:hover
        background darken($red, 10%)

      svg
        width 6px

    img
      background $gray
      border-radius 10px
      width 100%
      object-fit cover
      object-position center

.codex-editor
  z-index 2 !important

.codex-editor__redactor
  padding 15px 0 !important

.multiselect__single
  white-space: nowrap;
  overflow: hidden;

.multiselect__input
.multiselect__single
  height auto !important
  margin: 0 !important;
  font-size 1em !important;
  line-height 1.5em !important

.multiselect__placeholder
  color: #757575 !important
  font-size: 1em !important;
  padding-left: 5px !important;
  padding-top: 0 !important;
  margin: 0 !important;
  line-height: 1.5em !important

.multiselect
  @media print
    min-height: auto;

.multiselect__select
  margin auto !important
  bottom 1px
  @media print
    display none

.multiselect__tag-icon:focus
.multiselect__tag-icon:hover
  background: $red !important

.multiselect__tag-icon:after
  color $white !important

.multiselect__option
  white-space initial

.multiselect__tag
.multiselect__option--highlight
.multiselect__option--highlight:after
  background $dark_blue !important

.multiselect__spinner
  border-color $dark_blue transparent transparent !important

.multiselect__tags
  min-height 40px !important
  border-radius 10px !important
  border 1px solid darken($gray, 20%) !important
  @media print
    padding: 0;
    border: none !important;
    min-height: auto !important;
