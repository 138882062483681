@import '~@/stylus/mixins/textcut'
.mod-input {
  display grid
  width 100%
  align-items start
  justify-content start
  grid-template-columns 35fr 65fr
  grid-gap 8px
  margin 0

  .print-list {
    display none
    @media print {
      display flex
      flex-direction column
    }
  }

  .no-print {
    @media print {
      display none
    }
  }

  &--loading &__container {
    border-radius var(--radius)
    text-align center
    display flex
    align-items center
    justify-content center
    color var(--color_white)
    padding 0 16px
    height 44px
    cursor progress
    background-color var(--color_blue)
    background-size: 40px 40px;
    background-image: linear-gradient(-45deg, rgba(#000, .25) 25%, #0000 25%, #0000 50%, rgba(#000, .25) 50%, rgba(#000, .25) 75%, #0000 75%, #0000);
    animation: loader 2s linear 0s infinite normal none running;
  }

  &--error .multiselect__tags
  &--error textarea
  &--error input {
    border-color var(--error_red) !important
  }

  &__error {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 18px;
    display: flex;
    align-items: flex-end;
    color: var(--error_red);
  }

  .multiselect__tags {
    font-size inherit
  }

  .multiselect__single {
    color: var(--color_dark);
    opacity: 1;
    font-weight: 400;
    font-size 1em !important
    textcut(1)
  }

  &__container {
    display: flex;
    flex-direction column
    align-items stretch
    justify-content flex-start
    gap 8px

    > input
    > textarea {
      padding: 8px 14px;
      margin 0
      line-height 26px
      font-weight: 400;
      font-size 1em !important
      color: var(--color_dark);
    }
  }

  &__title {
    margin-top 10px
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_dark);
  }

  &__required-star
  &__title {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
  }

  &__required-star {
    color: var(--color_red);
  }

  &__radios {
    display flex
    flex-direction column
    align-items flex-start
    justify-content flex-start
    gap 8px
  }

  &__radio {
    margin 0
    display: flex;
    align-items center
    justify-content flex-start
    gap 12px
    cursor pointer
    width 100%
    @media print {
      input:not(:checked) {
        display none
      }
    }
  }

  &__radio-input {
    margin 0 !important
    width 20px
    height 20px
  }

  &__radio-title {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
  }
}

.mod-field {
  display grid
  width 100%
  align-items start
  justify-content start
  grid-template-columns 35fr 65fr
  grid-gap 8px
  margin 0

  &__title {
    margin-top 10px
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--main_border_color);
    @media print {
      margin-top 0
    }
  }

  &__container {
    background: var(--color_gray_light);
    background-blend-mode: darken;
    border-radius: var(--radius);
    padding 10px 14px
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_dark);
    min-height 100%
    @media print {
      padding 0
    }

    &--y {
      background-color var(--color_yellow_night_o1)
    }

    &--r {
      background-color var(--color_red_o1)
    }

    &--g {
      background-color var(--color_green_o1)
    }

    &--b {
      background-color var(--color_light_blue_o1)
    }
  }

  &__image {
    background: var(--color_gray_divider);
    border-radius: var(--radius);
  }

  &__divider-title {
    font-weight: 500;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_gray_dark);
    text-transform: uppercase;
    margin-top 16px
    @media print {
      margin-top 8px
    }
  }

  &__doc {
    display grid
    grid-template-columns 36px auto
    grid-gap 8px
    @media print {
      grid-template-columns auto
      .icon {
        display none
      }

      &::after {
        content: "[" attr(href) "]";
        white-space: pre;
      }
    }

    .icon svg {
      width 36px
      height 36px
    }
  }

  &__doc-content {
    display flex
    flex-direction column
    gap 4px
  }

  &__doc-title {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--main_color);
    line-break: anywhere;
  }

  &__doc-subtitle {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_gray_dark);
    text-transform uppercase
    @media print {
      display none
    }
  }
}
