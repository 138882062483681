*
  margin 0
  padding 0
  box-sizing border-box
  min-height 0
  min-width 0

html, .root
  font-size 14px
  line-height 26px

  +below(480px)
    font-size 12px

sc_main_radius = 5px
sc_color_light_blue = #19C1F2
sc_color_blue = #0377C4
sc_main_color = #02548A
sc_color_blue_dark = #02548A
sc_color_gray_blue = #79869F
sc_color_white = #FFFFFF
sc_color_gray_light = #F5F5F7
sc_color_gray_divider = #E5E5EB
sc_color_gray = #D9D9DE
sc_main_border_color = #A7A8AB
sc_color_gray_dark = #76767A
sc_color_dark = #333333
sc_color_black = #000000
sc_color_yellow_night = #D6B902
sc_color_green = #43B85F
sc_color_red_light = #EB4747
sc_color_red = #DA2127
sc_color_error_red = #FF0000
html
  --color_white sc_color_white
  --radius sc_main_radius
  --color_black_o1 rgba(sc_color_black, .1)
  --color_red_light sc_color_red_light
  --color_black_o08 rgba(sc_color_black, .08)
  --color_red sc_color_red
  --main_color sc_main_color
  --color_gray_divider sc_color_gray_divider
  --color_gray_light sc_color_gray_light
  --color_gray_blue sc_color_gray_blue
  --color_blue sc_color_blue
  --color_blue_dark sc_color_blue_dark
  --error_red sc_color_error_red
  --color_gray sc_color_gray
  --color_yellow_night sc_color_yellow_night
  --color_green sc_color_green
  --color_light_blue sc_color_light_blue
  --color_dark sc_color_dark
  --color_black sc_color_black
  --color_white_o85 rgba(sc_color_white, .85)
  --main_border_color sc_main_border_color
  --color_gray_dark sc_color_gray_dark
  --color_yellow_night_o1 rgba(sc_color_yellow_night, .1)
  --color_red_o1 rgba(sc_color_red, .1)
  --color_green_o1 rgba(sc_color_green, .1)
  --color_light_blue_o1 rgba(sc_color_light_blue, .1)

html
body
  margin 0
  padding 0
  font-family font_regular
  color $dark_blue
  background $gray

a
  color $dark_blue
  text-decoration none
  position relative
  border-bottom 1px solid transparent
  transition all 0.3s

  &:after
    left inherit
    right 50%

  &:hover
  &:focus
    text-decoration none
    color darken($dark_blue, 20%)
    border-color transparent

  &:focus
    outline none

  &:focus
  &:active
    text-decoration none

.contextual-links-wrapper a
.contextual-links a
  border-bottom none !important;

::selection
  background darken($dark_blue, 20%)
  color $white
  text-shadow none

::-moz-selection
  background darken($dark_blue, 20%)
  color $white
  text-shadow none

h1
h2
h3
h4
h5
h6
  font-family font_bold
  font-weight normal
  line-height normal
  margin-top 30px
  margin-bottom 30px

  &:first-child
    margin-top 0

h1
  font-size 3em

h2
  font-size 2em

  +below(480px)
    font-size 1.8em

h3
h4
h5
  font-size 1.625em

  +below(480px)
    font-size 1.2em

b
strong
dt
  font-family font_bold
  font-weight normal
  line-height normal

i
em
  font-weight normal
  font-family font_regular

  &:before
    margin 0
    width auto

pre
code
  margin 0
  border none
  background none

p
  margin-bottom 15px

  &:last-child
    margin-bottom 0

img
  vertical-align middle
  width 100%
  height auto
  max-width 100%

  &[data-src]
  &[data-srcset]
    display block
    min-height 1px

/*************blockquote**************/
blockquote
  background $gray
  padding 30px
  padding 30px
  margin 10px 0 30px
  font-family font_bold
  position relative
  overflow hidden

  p
    position relative

  &:after
    content ''
    font-family 'fontello'
    absolute right -20px bottom -30px
    transform rotate(150deg)
    margin 0
    line-height normal
    font-size 10em
    color alpha($dark_blue, 0.05)

/***************Aligns****************/
.align-left
.align-right
  float left
  margin 0 20px 15px 0
  max-width 40%

  +below(480px)
    float none
    margin 0 0 15px !important
    max-width 100%

  a
    imagehover($white)
    border none

  img
    width 100%
    height auto

.align-right
  float right
  margin 0 0 15px 20px

  +below(480px)
    float none
    margin 0 0 15px !important

.align-center
  display block

  img
    width 100%
    height auto

.align-justify
  text-align justify

figcaption
  font-size 0.8em
  padding 5px 0
  color lighten($dark_blue, 30%)

/*details*/
details
  margin-bottom 30px
  background $gray
  border-radius 10px

  summary
    background $gray
    font-family font_bold
    padding 10px
    border-top-right-radius 10px
    border-top-left-radius 10px
    cursor pointer

  > div
    padding 30px

  input
  textarea
  select
    background $white
    border 1px solid $white
    margin-bottom 15px

  textarea
    width 100%

  select
    appearance none
    -moz-appearance none
    -webkit-appearance none
    border-radius 10px
    margin 0 0 15px
    border 1px solid $white
    padding 10px 40px 10px 10px
    background-image linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)
    background-position calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px)
    background-size 5px 5px, 5px 5px
    background-repeat no-repeat

  label
    display block
    margin-bottom 5px
    font-family font_bold

/*********Table*************/
table:not(.tc-table)
  width 100%
  border-collapse collapse
  border 1px solid $gray
  margin-bottom 15px

  thead
    tr
      background $gray

      th
        padding 12px
        text-align left

  tbody
    tr
      border-bottom 1px solid $gray
      transition 0.3s

      &:last-child
        border-bottom none

      td
        padding 12px
        vertical-align middle

      &:hover
        background alpha($gray, 30%)
        transition 0.3s

/**********Fieldset**********/
fieldset
  legend
    padding 15px 0

/*coderteam*/
.coderteam
  fixed left bottom 10%
  z-index 99999

  img
    width 13px
    transform rotate(180deg)

/*************pretty checkbox************/
.pretty
  margin-right 0.5em !important

  input:checked ~ .state.p-defcolor label:after
  .pretty.p-toggle .state.p-defcolor label:after
    background-color $red !important

body .pretty.p-default input:checked ~ .state label:after
  background-color $red !important
