.section_login
  width 100%
  min-height 100vh
  display grid
  grid-template-columns 1fr 1fr

  +below(980px)
    display flex
    align-items center
    justify-content center
    flex-direction column
    background $white

.section_login__left
  background $dark_blue
  display flex
  align-items center
  justify-content center

  .logo
    display inline-flex

    svg
      fill $white
      width 200px
      height auto

  +below(980px)
    width 100%
    height 100px

    .logo
      svg
        width auto
        height 50px

.section_login__right
  background $white
  display flex
  align-items center
  justify-content center
  padding 50px

  .login
    width 100%
    max-width 400px

  +below(980px)
    flex 1
    width 100%

  +below(480px)
    padding 30px

.login__title
  font-size 2em
  font-family font_bold
  margin-bottom 30px
