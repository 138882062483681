.header
  height 60px

.header__stick
  height 60px
  background $dark_blue

  .container
    display flex
    align-items center
    justify-content space-between

.header__logo

  a
    display inline-flex
    height 60px
    align-items center
    padding 10px 15px 10px 0
    flex-direction row

  svg
    width auto
    height 40px
    fill $white
    margin 0 5px 0 0

  +below(980px)
    flex 1

  .header__logo-title
    color white
    font-size 14px
    font-weight bold



.header__nav
  flex 1
  padding: 0 25px;

  +below(980px)
    display none

  .main_nav__parent
    display flex
    align-items center


    svg
      width 20px
      max-height 20px
      fill $white
      display flex
      margin-right 5px

    li
      list-style none
      position relative

    > li

      > a
        display flex
        align-items center
        color $white
        opacity 0.5
        height 60px
        padding 0 15px 0 15px

        &.active
        &:hover
          background $white
          color $dark_blue
          opacity 1

          svg
            fill $dark_blue

      &:hover

        a
          background $white
          color $dark_blue
          opacity 1

          svg
            fill $dark_blue

        .main_nav__child
          opacity 1
          top 60px
          pointer-events auto

  .main_nav__child
    absolute left top 70px
    opacity 0
    pointer-events none
    width 250px
    box-shadow alpha($dark_blue, 20%) 0 30px 60px
    transition all 0.3s
    z-index 100

    > li

      > a
        display flex
        align-items center
        justify-content space-between
        height auto
        opacity 1
        color $dark_blue
        padding 10px 15px
        border-top 1px solid $darkgray
        background $white

        svg
          fill $dark_blue
          width 5px

        span
          flex 1

        &:hover
          background $gray
          color $dark_blue

      &:hover > .main_nav__second_child
          opacity 1
          pointer-events auto
          left 100%

  .main_nav__second_child
    absolute left 99% top
    opacity 0
    pointer-events none
    width 250px
    box-shadow alpha($dark_blue, 20%) 0 30px 60px
    transition all 0.3s
    z-index 101

    li
      a
        display flex
        height auto
        opacity 1
        color $dark_blue
        padding 10px 15px
        border-top 1px solid $darkgray
        background $white

        svg
          fill $dark_blue
          width 5px

        &:hover
          background $gray
          color $dark_blue

      &:hover > .main_nav__second_child
        opacity 1
        pointer-events auto
        left 100%

.header__menu__link

  &-title
    white-space nowrap



.header__dropmenu__link
  display flex
  align-items center
  justify-content space-between

  svg
    fill $dark_blue
    width: 5px;
    max-height: 20px;
    display: flex;
    margin-right: 5px;

  &:hover ~ .header__dropmenu
    opacity 1
    pointer-events auto
    left 100%


.header__user
  a
    display flex
    align-items center
    color $white
    opacity 0.5

    &:hover
    &:focus
      opacity 1

  svg
    fill $white
    width 20px
    max-height 20px
    margin-left 8px

.mobile_button
  display none
  height 60px
  align-items center
  color $white
  opacity 0.5
  margin 0 15px

  &:hover
  &:focus
    opacity 1

  i
    width 24px
    height 24px
    margin-left 8px

    svg
      width 100%
      height 100%
      stroke $white

  +below(980px)
    display flex
    justify-content flex-end

.mobile_nav
  display none

  .icon
    display none !important

.hamburger-box
  margin-left 5px
  width 30px
  top 1px

.hamburger-inner
.hamburger-inner::after
.hamburger-inner::before
  width 30px
  height 2px
  background-color $white
  background $white !important
