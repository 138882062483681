.map-filter {
  background: var(--main_color);
  border-radius: var(--radius);
  width 400px
  height 180px
  padding 8px 16px 12px

  &__header {
    display flex
    justify-content space-between
    align-items center
    padding-bottom 8px
    margin-bottom 8px
    border-bottom 1px solid var(--color_gray)
  }

  &__header-messages {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_white);

    &--op {
      opacity: 0.4;
    }
  }

  &__list {
    display grid
    grid-gap 8px
  }

  &__item {
    display: grid;
    cursor pointer
    grid-gap 8px
    align-items center
    grid-template-columns 24px 24px auto minmax(24px, 34px)
  }

  &__indicator {
    border-radius 100%
    width 20px
    height 20px
    flex-shrink 0
    background-color var(--color_gray)
    &--y {
      background-color var(--color_yellow)
    }
    &--r {
      background-color var(--color_red)
    }
    &--g {
      background-color var(--color_green)
    }
    &--b {
      background-color var(--color_light_blue)
    }
  }
  &__title {
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    color: var(--color_white);
  }

  &__count {
    font-weight: 500;
    font-size: 1.125em;
    line-height: 24px;
    color: var(--color_white);
    display flex
    justify-content flex-end
  }

  &__check {
    background: var(--color_gray_divider);
    height 20px
    width 20px
    border-radius: 2px;

    .icon {
      display flex
      align-items: center;
      justify-content: center;
      height: 100%;

      svg {
        width: 12px;
        height: 12px;
      }
    }
  }
}
