.app
  display flex
  flex-direction column
  min-height 100vh

$header_height = 60px
$footer_height = 66px
.main
  flex 1;
  display: flex;
  flex-direction: column;
  min-height: 'calc(100vh - %s - %s)' % ($header_height $footer_height);

.page
  padding 30px 0
  height 100%
  background $white
  flex 1

  &__edit
    width 100%
    flex 1

.content
  display flex
  align-items: flex-start;

  +below(768px)
    flex-direction column

.content__left
  flex 1

.content__right

  +below(768px)
    padding 30px 0 0

.main__buttons
.main__filters
  margin-bottom 30px
