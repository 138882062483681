@import "~@/stylus/mixins/svg.styl"
.sc {
  .no-print {
    @media print {
      display none
    }
  }

  > .map-modal {
    absolute left 14px bottom 14px
    z-index 1
  }

  &__map-label {
    display flex
    align-items center
    justify-content center

    span {
      background: var(--color_white);
      border-radius: var(--radius);
      font-weight: 500;
      font-size: 1.125em;
      line-height: 20px;
      text-transform: uppercase;
      color: var(--color_dark);
      padding 14px 24px
      box-shadow: 1px 2px 5px var(--color_black_o1);
    }
  }
  &__map {
    position relative
    display: flex;
    justify-content center
    overflow hidden
    height calc(100vh - 145px)

    &--crosshair ymaps {
      cursor crosshair !important
    }

    & > *:not(.sc__map-ui) {
      width 100%
      height 100%
    }

    > .map-ms {
      absolute left 14px top 14px
    }

    > .sc__map-hint {
      absolute left 14px bottom 14px
    }
  }

  &__map-label {
    absolute top 14px
  }

  &__map-report {
    background: var(--color_red_light);
    border-radius: 30px;
    height 40px
    border none
    cursor pointer
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_white);
    padding 0 25px 0 20px
    text-align left
    display flex
    justify-content center
    align-items center
    position relative
    gap 8px
    box-shadow: 5px 6px 12px var(--color_black_o08);
    transition .3s

    &:hover {
      background var(--color_red);
    }

    .icon {
      display flex

      svg {
        width 20px
        height 20px
      }
    }
  }

  &__map-filter {
    absolute right 14px top 14px
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: flex-end;
  }

  &__map-controls {
    absolute right bottom
    display flex
    padding 14px
    gap 16px
  }

  &__map-types {
    display flex
    flex-direction column
    gap 8px
    justify-content: flex-end;
    z-index 1
  }

  &__map-type {
    background: var(--color_white);
    box-shadow: 1px 2px 5px var(--color_black_o1);
    border-radius: var(--radius);
    display flex
    align-items center
    border: 1px solid transparent;
    height 44px
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    text-align: center;
    color: var(--color_dark);
    padding 10px
    gap 10px
    transition .3s
    cursor pointer

    .icon {
      display inline-flex
    }

    &:hover {
      border-color var(--main_color)
    }

    &--active {
      background var(--color_gray_divider)
      border-color var(--main_color)
    }
  }

  &__map-buttons {
    display: flex;
    flex-direction: column;
    gap 8px
    z-index 2
  }

  &__map-button {
    background: var(--color_white);
    border: 1px solid var(--color_gray_light);
    box-sizing: border-box;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content center
    width 44px
    height 44px
    cursor pointer
    transition .3s

    &:hover {
      border-color var(--main_color)
    }

    &--active {
      border-color var(--main_color)
      background var(--color_gray_light)
    }

    .icon {
      display inline-flex
    }
  }

  &__cluster-icon {
    width 36px
    height 36px
    border-radius 100%
    color var(--color_white)
    font-weight: 500;
    font-size: 1em;
    line-height: 24px;
    background var(--color_gray_blue)
    display flex
    justify-content center
    align-items center
    flex-shrink 0
  }

  .slide-below-enter-active, .slide-below-leave-active {
    transition: .25s;
    transform translateY(0%)
  }

  .slide-below-enter, .slide-below-leave-to {
    transform translateY(100%)
    opacity: 0;
  }

  .slide-right-enter-active, .slide-right-leave-active {
    transition: .25s;
    transform translateX(0%)
  }

  .slide-right-enter, .slide-right-leave-to {
    transform translateX(100%)
    opacity: 0;
  }
}

.mod {
  display flex
  flex-direction column
  width 550px
  background var(--color_white)
  border-radius 20px
  margin auto
  z-index 2

  .no-print {
    @media print {
      display none
    }
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap 8px
  }

  &__buttons-accept {
    background: var(--color_blue);
    border 1px solid var(--color_blue);
    border-radius: var(--radius);
    font-weight: normal;
    font-size: 1em;
    line-height: 24px;
    text-align: center;
    height 44px
    padding 0 16px
    cursor pointer
    color: var(--color_white);
    transition .3s

    &--loading {
      cursor progress
      background-color var(--color_blue)
      background-size: 40px 40px;
      background-image: linear-gradient(-45deg, rgba(#000, .25) 25%, #0000 25%, #0000 50%, rgba(#000, .25) 50%, rgba(#000, .25) 75%, #0000 75%, #0000);
      animation: loader 2s linear 0s infinite normal none running;
    }

    &:not(&--loading):focus
    &:not(&--loading):hover {
      background var(--color_blue_dark)
      border-color var(--color_blue_dark)
    }

    &:not(&--loading):active {
      transform scale(0.95)
    }
  }

  &__buttons-decline {
    @extend .mod__buttons-accept
    background var(--color_white)
    color var(--color_blue)

    &:not(&--loading):focus
    &:not(&--loading):hover {
      color var(--color_blue_dark) !important
      background var(--color_white) !important
    }
  }

  &__form {
    display flex
    flex-direction column
    gap 16px
    padding 0 16px 8px 8px
    @media print {
      gap 4px
      padding 0
    }
  }

  &__checkbox {
    display flex
    flex-direction column
    gap 8px
    cursor pointer
    margin 0
  }

  &__checkbox-message {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 18px;
    display: flex;
    align-items: flex-end;
    color: var(--error_red);
  }

  &__checkbox-container {
    display flex
    align-items flex-start
    gap 10px
  }

  &__checkbox-input {
    margin: 0 !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 2px;
    border: 1px solid var(--color_dark);
  }

  &__checkbox-title {
    font-weight: normal;
    font-size: 0.875em;
    line-height: 20px;
    color: var(--color_dark);

    a {
      color var(--color_blue_dark)
    }
  }

  &__required-mark {
    font-weight: normal;
    font-size: 0.75em;
    line-height: 18px;
    color: var(--color_red);
  }

  &__body {
    min-height 20px
    display flex
    flex-direction column
    margin 16px
    gap 18px
  }

  &__header {
    display grid
    align-items center
    padding 16px
    grid-gap 10px
    grid-template-columns 24px auto auto
    box-shadow: 5px 6px 12px var(--color_black_o08);
    @media print {
      box-shadow: none
      border-bottom: 1px solid;
    }
  }

  &__header-buttons {
    display flex
    align-items center
    justify-content: flex-end;
    gap 10px
    @media print {
      display none
    }
  }

  &__close {
    background none
    border none
    cursor pointer

    .icon {
      display: flex;
      align-items center
      justify-content center

      svg {
        width 20px
        height 20px
        svg(var(--color_dark))
      }
    }
  }

  &__icon {
    svg path {
      fill var(--color_blue)
    }
  }

  &__indicator {
    display flex
    width 24px
    height 24px
    border-radius 100%
    flex-shrink 0
    background var(--color_gray)

    &--y {
      background-color var(--color_yellow_night)
    }

    &--r {
      background-color var(--color_red)
    }

    &--g {
      background-color var(--color_green)
    }

    &--b {
      background-color var(--color_light_blue)
    }
  }
}

.status {
  padding 4px 8px
  border-radius 10px
  border 1px solid var(--color_gray_divider)
  background var(--color_gray_light)
  font-weight: normal;
  font-size: 1em;
  line-height: 24px;
  text-align: center;
  color: var(--color_dark);

  &--y {
    background rgba($yellow, 0.2)
  }

  &--r {
    background rgba($red, 0.2)
  }

  &--g {
    background rgba($green, 0.2)
  }

  &--b {
    background rgba($blue, 0.2)
  }
}
