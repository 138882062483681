.filter__content
  +below(768px)
    margin-top 20px
    display none
    &.active
      display initial

.filters__mobile
  display none
  +below(768px)
    display block
