.map-table {
  z-index 1
  transition .3s
  background var(--color_white)
  display flex
  flex-direction column
  align-items center
  justify-content center
  position relative

  &__row > * {
    margin-top: auto;
  }

  &__container {
    display flex
    flex-direction column
    width 100%
    overflow-y scroll
    padding 16px 32px
    margin-bottom: auto;

    & > * {
      flex-shrink 0
    }
  }

  &__form {
    margin-bottom 16px
  }

  &__date {
    display grid
    grid-template-columns 1fr 1fr
    grid-gap 16px
    align-items end
  }

  &__buttons {
    display grid
    grid-template-columns repeat(4, auto)
    grid-gap 8px
    align-items flex-end

    .form__item.checkbox {
      height 40px
    }
  }

  .excel-btn {
    @extend .btn
    background #2F8143
    border-color #2F8143
    padding: 5px;
    display: grid;
    grid-template-columns: 20px 20px;
    grid-gap: 4px;
    &:focus
    &:active
    &:hover {
      background darken(#2F8143, 10%)
      border-color darken(#2F8143, 10%)
    }
  }

  &__toggle {
    cursor pointer
    margin auto
    absolute top -36px
    background var(--color_white)
    padding 8px 16px
    font-size: 0.875em;
    line-height: 20px;
    text-align: center;
    border none
    color: #333;
    border-radius: 8px 8px 0 0;
    display grid
    align-items center
    grid-template-columns 24px auto 24px
    grid-gap 10px

    .icon-down {
      width 18px
      height 18px
      transition .3s
      transform rotate(180deg)

      ^[0].active & {
        transform rotate(0deg)
      }

      path {
        stroke #333
      }
    }
  }
}
